import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "empty-state-text"
    }}>{`Empty State Text`}</h1>
    <p>{`As a part of any design, it’s always helpful to consider the empty state of a feature or component
(what the space would look like before a user takes any action at all), and what information a user
needs in the context of the empty state.`}</p>
    <ul>
      <li parentName="ul">{`If the user can take an action from the empty state, tell them what they can do.`}</li>
      <li parentName="ul">{`Always lean towards a more positive tone. Emphasize what users haven’t done yet, not what they
don’t have.`}</li>
    </ul>
    <h2 {...{
      "id": "elements-of-an-empty-state"
    }}>{`Elements of an Empty State`}</h2>
    <p>{`Empty states may include any combination of the following elements: a title, body text, and a CTA
button. See the `}<a parentName="p" {...{
        "href": "/styles/assets/empty-states"
      }}>{`Empty States article`}</a>{` for more
design guidance.`}</p>
    <h3 {...{
      "id": "titles-and-body-text"
    }}>{`Titles and Body Text`}</h3>
    <ul>
      <li parentName="ul">{`Write titles in title case, with no period at the end.`}</li>
      <li parentName="ul">{`Write body text in sentence case, and do use a period at the end of the sentence.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul"><strong parentName="li">{`Start Searching Now`}</strong></li>
        <li parentName="ul">{`Find people, articles, or tasks and reports.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Start searching now.`}</li>
        <li parentName="ul">{`Find people, articles, or tasks & reports.`}</li>
      </ul>
    </Dont>
    <h3 {...{
      "id": "call-to-action-buttons"
    }}>{`Call to Action Buttons`}</h3>
    <p>{`If possible, include a call to action button that takes users somewhere relevant to the context of
the empty state.`}</p>
    <ul>
      <li parentName="ul">{`Write button text that is engaging and that tells the user what they can do.`}</li>
      <li parentName="ul">{`Write in title case, and don't use a period at the end.`}</li>
      <li parentName="ul">{`Follow all other `}<a parentName="li" {...{
          "href": "/guidelines/content/ui-text/buttons-ctas"
        }}>{`button text guidelines`}</a>{`.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Customize`}</li>
        <li parentName="ul">{`Add Expenses`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      